.TextFieldInput {
  width: 100%;
}

.TextFieldInput button {
  color: var(--primaryGreen);
}

.TextFieldInput fieldset {
  border-radius: 1px;
}

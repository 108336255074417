@import "components/generic/TextFieldInput.css";

  @media only screen and (max-width: 360px) {
    .hxs {
      display: none;
    }
  }
  @media only screen and (max-width: 864px) {
    .hsm {
      display: none;
    }
  }

  @media only screen and (max-width: 1080px) {
    .hmd {
      display: none;
    }

    .version-box {
      max-width: 96px;
    }

  }
  body {
    font-weight: 400;
  }

  .version-box {
    width: 150px;
  }

  @media only screen and (max-width: 1366px) {
    .hxl {
      display: none;
    }
  }


  .centered-flex {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
  }

  .left-flex-global {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.left-flex {
  font-weight: 400;display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.right-flex-global {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}


  .right-flex {
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5rem;
    width: 32px;
    height: 32px;
  }

  .fullClean {
  margin: 0;
  padding: 0;
  width: 100%;
}

.smallPaddingRB {
  padding: 0 1rem 1rem 0;
}

.drawer {
    height: 100vh;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    min-width: 400px;
  }

  .pillFilter {
    margin: 0 0.2rem !important;
    background-color: #CAFFCC !important;
    color: black;
  }

  .pillLabel {
    margin-right: 0.2rem !important;
  }


  .MuiPaper-root .MuiAccordion-root {
    border-top: #D9D9D9 1px solid;
  }

  .MuiFormLabel-root {
    color: black;
  }

  .MuiButton-root.btn {
    height: 2rem;
    margin: 0 0.5rem 0 0;
    padding: 0.313rem 0.938rem;
    min-width: 6.5rem;
  }

  .flex-separator {
    display: flex;
    flex-grow: 1;
  }

  .MuiButtonBase-root.MuiButton-root.square-button {
    margin: auto;
    width: 2rem;
    max-width: 2rem;
    min-width: 1rem;
    height: 2rem;
    z-index: 3;
  }

  .icon {
    width: 1.25rem;
    height: 1.25rem;
  }
.iconSmall {
  width: 1rem !important;
  height: 1rem !important;
}

  .reset-link-decoration {
    margin: auto;
    text-decoration: none;
    color: white;
  }

.moduleTitle {
  padding: 0;
}

.moduleTitle > p:first-child {
  text-transform: uppercase;
}

.boxInCenterView {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
}

.boxInCenterView .MuiPaper-root {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 3px 3px 6px 1px rgba(0, 0, 0, 0.25) !important;
  padding: .75rem 4rem;
}

.boxInCenterView.error .MuiPaper-root {
  padding: 2rem;
}

.boxInCenterView .MuiCircularProgress-root + p {
  color: #009c10;
  margin: .5rem 0;
}

.error .MuiSvgIcon-root {
  height: 3rem;
  width: 3rem;
  color: #f44336;
}

.error .svgTitle {
  font-weight: bold;
  color: #f44336;
  margin-top: .5rem;
}

.error .errorContent {
  padding: .75rem 3rem;
  text-align: center;
}

.btn {
  margin: 1rem 0;
}

.error .btn {
  margin: .5rem 0;
}

.loadingButton {
  margin-left: .3rem;
}

.MuiListItem-root.ulStyleNone {
    color: black;
    list-style-position: inside;
    padding: 0;
  }

.title {
  text-transform: uppercase;
  text-align: center;
  padding: 0;
}

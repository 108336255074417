.iconWrapper {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 1.5rem 0 0;
  z-index: 10;
  cursor: pointer;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
}
.pulse {
  animation: pulse-animation 2s infinite;
}
@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}

